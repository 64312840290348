const translations: any = {
  'lv': {
    'title': 'Root XP',
    'header': 'Viss balstās uz pieredzi',
    'subheader': 'Veidosim to LIELISKU kopā!',
    'what_we_do': 'Ko mēs daram?',
    'what_we_do_description': 'Mēs darbojamies IT sfērā - sniedzam konsultācijas, mentorējam, aktīvi stradājam pie dažādām WEB sistēmām un video spēlēm',
    'mentoring': 'MENTORINGS',
    'web_systems': 'WEB SISTĒMAS',
    'gamification': 'SPĒĻOŠANA',
    'our_tech_stack': 'DAŽAS NO TEHNOLOĢIJĀM KURAS MĒS PĀRZINAM UN LIETOJAM',
    'ready_to_learn': 'Mēs vienmēr esam gatavi izmēģināt ko jaunu un mesties ar jums kopā kādā piedzīvojumā!',
    'see_our_projects': 'Apskati dažus no mūsu proktiem ->',
    'who_we_are': 'Kas mēs esam?',
    'who_we_are_description_1': 'Profesionāļu komanda kuri ❤️ to ko dara un lepojas ar padarīto!<br> SIA Root XP ir dibināts 2021. gada pavasarī kā vieta kurā īstenot savus sapņus, idejas un palīdzēt arī citiem īstenot viņējos.',
    'who_we_are_description_2': 'Pie mums strādā profesionāļi, kuri izbauda darba procesu un ir ar visu sirdi un dvēlesi tajā ko dara. Dažiem no mums profesionālā darba pieredze IT jomā ir vairāk kā 10 gadus un esam strādājuši dažādos vadošajos IT uzņēmumos Latvijā.',
    'some_of_our_projects': 'DAŽI NO MŪSU PROJEKTIEM',
    'services': 'Pakalpojumi',
    'services_subtitle': 'Norādītajai informācijai ir informatīvs raksturs, par precīzāku darba apjomu un iespējām, lūdzu, sazinies ar mums privāti.',
    'budget': 'BUDŽETS',
    'service_includes': 'Šajā kategorijā ietilpst:',
    'services_1_description': '',
    'services_2_description': '',
    'services_3_description': '',
    'email': 'e-pasts',
    'location': 'birojs',
    'remote_work': 'šobrīd strādājam attālināti',
    'contacts': 'Kontakti',
    'meeting_possibilities': 'Iepriekš saskaņojot iespējama tikšanās Rīgā un tās rajonā',
    'bussiness_details': 'Rekvizīti',
  }
}

let language = 'lv'

export function translate(name: string) {
  return translations[language][name] || `%${name}%`
}
