import {BsFacebook, BsLinkedin, BsYoutube, BsFillPinMapFill, BsEnvelope} from 'react-icons/bs'
import {translate} from "../../store/translations";

export default function () {
    return <footer className="full-stripe relative flex flex-col ali p-8 mt-xl">
        <div className="text-white m-auto flex flex-col sm:flex-row sm:justify-between w-full">
            <div className="flex flex-col gap-xs">
                <div>
                    <div className="uppercase text-sm leading-sm font-bold">
                        { translate('contacts') }
                    </div>
                    <div className="text-xs leading-xs mt-xs">
                        { translate('meeting_possibilities') }
                    </div>
                </div>
                <div className="flex gap-md align-bottom mt-sm">
                    <div className="flex justify-center align-middle h-100 overflow-hidden rounded-full bg-blue w-[40px] h-[40px]">
                        <BsFillPinMapFill className="text-dark text-md self-center"/>
                    </div>
                    <div className="flex flex-col gap-xxs text-xs leading-xs">
                        <span className="uppercase">
                            { translate('location') }
                        </span>
                        <a href="mailto:info@rootxp.dev" className="">
                            { translate('remote_work') }
                        </a>
                    </div>
                </div>
                <div className="flex gap-md align-bottom mt-sm">
                    <div className="flex justify-center align-middle h-100 overflow-hidden rounded-full bg-blue w-[40px] h-[40px]">
                        <BsEnvelope className="text-dark text-md self-center"/>
                    </div>
                    <div className="flex flex-col gap-xxs text-xs leading-xs">
                        <span className="uppercase">
                            { translate('email') }
                        </span>
                        <a href="mailto:info@rootxp.dev">
                            info@rootxp.dev
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-xs mt-lg sm:mt-0 text-center sm:text-right justify-between">
                <div>
                    <div className="uppercase text-sm leading-sm font-bold">
                        { translate('bussiness_details') }
                    </div>
                    <div className="text-xs leading-xs mt-xs">
                        SIA "Root XP"
                    </div>
                    <div className="text-xs leading-xs mt-xs">
                        Reģ. nr. 40203313229
                    </div>
                    <div className="text-xs leading-xs mt-xs">
                        PVN nr. LV40203313229
                    </div>
                </div>
                <div className="uppercase mt-lg sm:mt-sm flex flex-col">
                    <div>
                        Seko mums sociālajos tīklos
                    </div>
                    <div className="flex gap-lg text-lg mt-xs w-full sm:justify-between justify-center">
                        <a href="https://www.facebook.com/rootxp.dev/" target="_blank" className="text-white hover:text-yellow">
                            <BsFacebook/>
                        </a>
                        <a href="https://lv.linkedin.com/company/rootxp" target="_blank" className="text-white hover:text-yellow">
                            <BsLinkedin/>
                        </a>
                        <a href="https://www.youtube.com/@rootxpdev" target="_blank" className="text-white hover:text-yellow">
                            <BsYoutube/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="text-white text-xs m-auto mt-8 mt-lg sm:mt-md">
            Copyright © 2023 RootXP
        </div>
    </footer>
}
