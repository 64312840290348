import React from 'react';
import './style.css';
import {translate} from "../../store/translations";
import {motion} from "framer-motion";

function OurStory() {
    return (
        <motion.div
            className="box"
            initial={{opacity: 0, scale: 1.0}}
            whileInView={{opacity: 1, scale: 1}}
            transition={{
                duration: 0.8,
                delay: 0.1,
                ease: [0, 0.71, 0.2, 1.01],
            }}
            viewport={{
                once: true,
            }}
        >
            <div className="flex our-story relative bg-white flex-col sm:flex-row mt-lg sm:mt-xl">
                <div className="w-auto bg-dark pb-md px-xs sm:px-md">
                    <div className="bg-white rounded-full overflow-hidden m-auto sm:m-0 w-[100px] h-[100px] p-xs mt-md sm:mt-md flex pt-[20px]">
                        <img className="bg-white logo h-auto" alt="logo" src="/images/logo.svg"/>
                    </div>
                </div>
                <div className="w-full sm:w-full bg-white text-center sm:text-left">
                    <p className="text-pink text-md mt-md px-xs sm:px-md">
                        { translate('who_we_are') }
                    </p>
                    <p className="text-xs mt-sm px-xs sm:px-md" dangerouslySetInnerHTML={{__html: translate('who_we_are_description_1')}}></p>
                    <p className="text-xs mt-sm px-xs sm:px-md" dangerouslySetInnerHTML={{__html: translate('who_we_are_description_2')}}></p>
                    <img className="px-0 sm:px-md mt-md mb-md" alt="together" src="/images/hands.jpeg"/>
                </div>
            </div>
        </motion.div>
    );
}


export default OurStory;
