import React from 'react';
import './style.css';
import {translate} from "../../store/translations";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";

const tech = [
    'php',
    'golang',
    'nodejs',
    'aws',
    'digital-ocean',
    'wordpress',
    'vue',
    'laravel',
    'react',
    'terraform',
]

function Tech() {
    return (
        <motion.div
            className="box"
            initial={{opacity: 0, scale: 1.0}}
            whileInView={{opacity: 1, scale: 1}}
            transition={{
                duration: 0.8,
                delay: 0.1,
                ease: [0, 0.71, 0.2, 1.01],
            }}
            viewport={{
                once: true,
            }}
        >
            <div className="tech px-xs">
                <p className="mt-xxl text-xs leading-sm md:text-md md:leading-md text-center">{ translate('our_tech_stack') }</p>
                <Marquee className="flex mt-md" speed={10} gradientWidth={50}>
                    { tech.map(item => (
                        <img key={item + '1'} alt={item} src={`/images/logos/${item}.svg`} className="h-[40px] w-auto pr-md"/>
                    )) }
                    { tech.map(item => (
                        <img key={item + '1'} alt={item} src={`/images/logos/${item}.svg`} className="h-[40px] w-auto pr-md"/>
                    )) }
                    { tech.map(item => (
                        <img key={item + '1'} alt={item} src={`/images/logos/${item}.svg`} className="h-[40px] w-auto pr-md"/>
                    )) }
                </Marquee>
                <p className="learn text-gray-500 mt-md sm:p-0 text-center invert-shadow relative text-xs">
                    { translate('ready_to_learn') }
                </p>
            </div>
        </motion.div>
    );
}


export default Tech;
