import React from 'react';
import './App.css';
import Header from "./components/header";
import AboutUs from "./components/about-us";
import Tech from "./components/tech";
import OurStory from "./components/our-story";
import Projects from "./components/projects";
import Services from "./components/services";
import Feedback from "./components/feedback";
import Footer from "./components/footer";

function App() {
  return (
    <div className="App">
        <Header/>
        <AboutUs/>
        <Tech/>
        <OurStory/>
        {/*<Projects/>*/}
        {/*<Services/>*/}
        {/*<Feedback/>*/}
        <Footer/>
    </div>
  );
}

export default App;
