import React from 'react';
import './style.css';
import {translate} from "../../store/translations";
import {motion} from "framer-motion";

function Index() {
    return (
        <motion.div
            className="box"
            initial={{opacity: 0, scale: 1.0}}
            whileInView={{opacity: 1, scale: 1}}
            transition={{
                duration: 0.8,
                delay: 0.1,
                ease: [0, 0.71, 0.2, 1.01],
            }}
            viewport={{
                once: true,
            }}
        >
            <header className="header">
                <div className="flex title flex-col sm:flex-row my-sm sm:px-sm">
                    <img alt="logo" src="/images/logo.svg" className="my-sm mr-0 h-[100px] sm:h-[160px] sm:my-lg sm:mr-md"/>
                    <div className="flex flex-col h-100 justify-center text-center gap-4 sm:gap-0">
                        <h1 className="text-dark text-lg leading-sm sm:text-xxl sm:leading-xxl text-center sm:text-left">{translate('header')}</h1>
                        <h2 className="text-dark sm:my-xs text-md leading-sm sm:text-lg sm:leading-lg text-center sm:text-left">{translate('subheader')}</h2>
                    </div>
                </div>
                <nav className="navigation-bar sm:justify-left gap-xxs sm:gap-sm bg-dark full-stripe flex-col text-center sm:flex-row mt-8 sm:mt-0 hidden sm:flex sm:py-[5px] sm:px-sm">
                    {[
                        '#IT',
                        '#Technology',
                        '#Gamification',
                        '#Blockchain',
                        '#Automation',
                    ].map((name) => (
                        <a key={name} href={name} className="text-xs text-white">{name}</a>
                    ))}
                </nav>
            </header>
        </motion.div>
    );
}


export default Index;
